import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

class AboutPage extends React.Component {
  componentDidMount() {
    if (window.location.pathname === '/aboutPage') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>

                <div className="page-prp">
                  <h1>
                    <FormattedMessage id="about" />
                  </h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                </div>

                <section className="about">
                  <div className="container">
                    <figure className="about-image">
                      <div className="about-prp">
                        <aside className="numbers">
                          <h1>8+</h1>
                          <h4>
                            <FormattedMessage id="years" />
                          </h4>
                        </aside>
                        <aside className="numbers">
                          <h1>7</h1>
                          <h4>
                            <FormattedMessage id="countries" />
                          </h4>
                        </aside>
                        <aside className="numbers">
                          <h1>150+</h1>
                          <h4>
                            <FormattedMessage id="customers" />
                          </h4>
                        </aside>
                      </div>
                    </figure>
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>
                          Since 2011, presenting more than 750+ products, KNS
                          has been building a bridge between physical and
                          digital world in 7 Countries, more than 150+ Shopping
                          Malls, Universities and City Hospitals.
                        </p>
                        <p>
                          For a sustainable customer relations experience, KNS
                          is implementing a one-stop management model on a
                          synchronized platform for its services such as Mobile
                          CRM and Loyalty integrations, web sites, native
                          applications and touchscreens.
                        </p>
                        <p>
                          Reaching over 250.000 end users through mobile
                          applications, KNS continues being the strongest
                          solution partner for shopping malls’ and their success
                          stories. Millions of mall visitors’ shopping behaviors
                          are being re-shaped via more than 200 touchscreens in
                          Turkey and across the globe.
                        </p>
                        <p>
                          Meeting all the digital marketing needs of shopping
                          malls, KNS is presenting basic, practical and customer
                          oriented solutions.
                        </p>
                      </>
                    )}

                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <p>
                          Since 2011, presenting more than 750+ products, KNS
                          has been building a bridge between physical and
                          digital world in 7 Countries, more than 150+ Shopping
                          Malls, Universities and City Hospitals.
                        </p>
                        <p>
                          For a sustainable customer relations experience, KNS
                          is implementing a one-stop management model on a
                          synchronized platform for its services such as Mobile
                          CRM and Loyalty integrations, web sites, native
                          applications and touchscreens.
                        </p>
                        <p>
                          Reaching over 250.000 end users through mobile
                          applications, KNS continues being the strongest
                          solution partner for shopping malls’ and their success
                          stories. Millions of mall visitors’ shopping behaviors
                          are being re-shaped via more than 200 touchscreens in
                          Turkey and across the globe.
                        </p>
                        <p>
                          Meeting all the digital marketing needs of shopping
                          malls, KNS is presenting basic, practical and customer
                          oriented solutions.
                        </p>
                      </>
                    )}


<div className="row">

<div className="col-md-3">
                        <a href="https://kns-web.s3.eu-central-1.amazonaws.com/files/SERT_KNS_B%C4%B0L%C4%B0%C5%9E%C4%B0M_22301_2022+(1)+(1).pdf" target="_blank" className="iso"><img
                    src={require('../assets/images/KNS-ISO-22301.png')}
                    alt="KNS"
                    title="KNS"
                  />
                  </a>
                  </div>

                      <div className="col-md-3">

                        <a href="https://kns-web.s3.eu-central-1.amazonaws.com/files/SERT_KNS_B%C4%B0L%C4%B0%C5%9E%C4%B0M_27001_2022+(1)+(1).pdf" target="_blank" className="iso"><img
                    src={require('../assets/images/KNS-ISO-27001.png')}
                    alt="KNS"
                    title="KNS"
                  />
                  </a>
                  </div>



            

                  </div>




                  </div>
                </section>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </Layout>
    )
  }
}
AboutPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default AboutPage
